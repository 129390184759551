import React from 'react';
import styled from 'styled-components';
import * as Mixins from '../Mixins';
import * as t from '../Typography';
import Layout, { Content } from '../components/Layout';
import SEO from '../components/SEO';
//import Placeholder from '../images/placeholder.png';
//import Cpp from '../images/icons8-c++.svg';
import { HireMe } from '../components/Button.js';
import HireMePopup from '../components/HireMePopup.js';
import { media } from '../MediaQueries';
import Colors from '../Colors';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import Resume from '../images/Singha_Resume.pdf';
//import { darken } from 'polished';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faJsSquare,
  faReact,
  faHtml5,
  faCss3,
  faWordpress,
  faPython,
  faPhp,
  faNode,
  faFacebookSquare,
  faTwitterSquare,
  faInstagram,
  faGithubSquare
} from '@fortawesome/free-brands-svg-icons';

const AboveFold = styled.div`
  ${Mixins.aboveFoldMixin}
  padding: 140px 0 60px 0;
  ${t.H1} {
    color: ${Colors.darkest};
  }
`;

const HomeHireButtom = styled(HireMe)`
  margin: 0.5rem 1rem;
`;
/*
const Block = styled.div`
  &:nth-child(odd) {
    border: solid 1px ${darken(0.1, Colors.light)};
    background-color: ${Colors.light};
  }
`;

const BlockContent = styled(Content)`
  ${Mixins.block}
  padding: 100px 40px;
  ${media.tablet`
    flex-direction: column;
    align-items: baseline;
  `};
  ${media.phone`
    padding: 40px 10px;
  `};
  ${t.P} {
    margin-top: 10px;
  }
  ${t.H2} {
    margin-top: 0;
  }
  img {
    width: 100%;
    height: auto;
  }
`;

const DivWrapper = styled.div`
  padding: 80px 30px;
  ${media.tablet`padding: 50px 0;`}
  &:first-child {
    ${media.tablet`
      margin-bottom: 40px;
  `};
  }
`;

const ItemImage = styled.img`
  max-width: 85%;
  position: relative;
  ${media.tablet`max-width: none;`}
`;
*/
const HomepageWrapper = styled.div`
  ${Mixins.wrapper}
  .who-desc {
    display: block;
    margin: 0 auto 60px auto;
    max-width: 90%;
  }
  ${t.LargeP} {
    margin-bottom: 28px;
  }
  ${t.H1} {
    margin: 0 0 20px 0;
  }
  .avatar {
    max-width: 200px;
    width: 80%;
    margin: 0 auto 50px auto;
    border-radius: 50%;
    display: block;
    ${media.tablet`max-width: 70%;`}
  }
  .link {
    padding: 0;
    color: ${Colors.darkest};
    text-decoration: underline;
    svg {
      margin-left: 7px;
    }
  }
  .portfolio {
    margin: 100px 0 50px 0;
    font-size: 42px;
  }
`;

const WorkWithMe = styled.div`
  padding: 80px;
  width: 73%;
  border-radius: 6px;
  box-shadow: 0 2px 26px 0 rgba(57, 55, 55, 0.08);
  background-color: #ffffff;
  text-align: center;
  position: relative;
  margin: 100px auto -150px auto;
  ${t.LargeP} {
    max-width: 80%;
    margin: 0 auto 28px auto;
  }
  ${media.tablet`
    width: auto;
    padding: 40px;
    margin: 50px 30px -100px 30px;
  `};
`;

const StackGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;

  ${media.phone`
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr)
  `}

  div {
    margin: 10px;
    text-align: center;
  }
`;

const IconDiv = styled.div`
  margin: 0 auto;
`;

const SocialDiv = styled.div`
  display: flex;
  margin: 20px auto;
  width: max-content;

  div {
    flex: 1;
    padding: 10px;

    a:hover {
      opacity: 0.85;
    }
  }
`;

class Homepage extends React.Component {
  state = {
    openHireMePopup: false
  };

  handleRequestDemoClose = () => {
    this.setState({
      openHireMePopup: false
    });
  };

  openContactPopup = () => {
    this.setState({
      openHireMePopup: true
    });
  };

  render() {
    const { openHireMePopup } = this.state;
    const { data } = this.props;
    return (
      <HomepageWrapper>
        <SEO title="Home" />
        <Layout theme="white" bigFooter openContactPopup={this.openContactPopup}>
          <AboveFold>
            <Img fluid={data.avatarHomepage.childImageSharp.fluid} alt="Shivam Singha" className="avatar" />
            <t.H1 primary align="center">
              Shivam Singha
            </t.H1>
            <t.LargeP align="center" max45>
              Currently doing a Degree in Computer Science & Engineering.
            </t.LargeP>
            <a href={Resume}>
              <HomeHireButtom large book>
                My Resume
              </HomeHireButtom>
            </a>
            <HomeHireButtom large onClick={this.openContactPopup} book>
              Hire me
            </HomeHireButtom>
          </AboveFold>
          <Content>
            <t.H2 primary align="center" bold>
              Languages I know
            </t.H2>
            <StackGrid>
              <div>
                <IconDiv>
                  <FontAwesomeIcon size="4x" icon={faHtml5} />
                </IconDiv>
                <h3>HTML5</h3>
              </div>
              <div>
                <IconDiv>
                  <FontAwesomeIcon size="4x" icon={faCss3} />
                </IconDiv>
                <h3>CSS3</h3>
              </div>
              <div>
                <IconDiv>
                  <FontAwesomeIcon size="4x" icon={faJsSquare} />
                </IconDiv>
                <h3>JS (ES6)</h3>
              </div>
              <div>
                <IconDiv>
                  <FontAwesomeIcon size="4x" icon={faPython} />
                </IconDiv>
                <h3>Python</h3>
              </div>
              <div>
                <IconDiv>
                  <FontAwesomeIcon size="4x" icon={faPhp} />
                </IconDiv>
                <h3>php</h3>
              </div>
            </StackGrid>
            <t.H2 primary align="center" bold>
              Tech stacks and frameworks I use
            </t.H2>
            <StackGrid>
              <div>
                <IconDiv>
                  <FontAwesomeIcon size="4x" icon={faReact} />
                </IconDiv>
                <h3>React.js</h3>
              </div>
              <div>
                <IconDiv>
                  <FontAwesomeIcon size="4x" icon={faWordpress} />
                </IconDiv>
                <h3>Wordpress</h3>
              </div>
              <div>
                <IconDiv>
                  <FontAwesomeIcon size="4x" icon={faNode} />
                </IconDiv>
                <h3>Node.js</h3>
              </div>
            </StackGrid>
          </Content>
          {
            null /*<Content>
            <t.H2 primary align="center" bold>
              Lorem ipsum
            </t.H2>
            <t.P align="center" max70 className="who-desc">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </t.P>
            <t.H2 primary align="center" bold className="portfolio">
              Portfolio
            </t.H2>
          </Content>
          <Block>
            <BlockContent>
              <DivWrapper>
                <ItemImage src={Placeholder} alt="Placeholder title" />
              </DivWrapper>
              <DivWrapper>
                <t.H2 bold>Placeholder website</t.H2>
                <t.P>Lorem ipsum</t.P>
                <t.P>Dolor sit amet</t.P>
                <LinkButton primary bold className="link" as="a"
                  target="_blank"
                  href="#">
                  Lorem ipsum
                </LinkButton>
              </DivWrapper>
            </BlockContent>
          </Block>
          <Block>
            <BlockContent>
              <DivWrapper>
                <t.H2 bold>Placeholder website</t.H2>
                <t.P>Lorem ipsum</t.P>
                <t.P>Dolor sit amet</t.P>
                <LinkButton primary bold className="link" as="a"
                  target="_blank"
                  href="#">
                  Lorem ipsum
                </LinkButton>
              </DivWrapper>
              <DivWrapper>
                <ItemImage src={Placeholder} alt="Placeholder title" />
              </DivWrapper>
            </BlockContent>
          </Block>
          <Block>
            <BlockContent>
              <DivWrapper>
                <ItemImage src={Placeholder} alt="Placeholder title" />
              </DivWrapper>
              <DivWrapper>
                <t.H2 bold>Placeholder website</t.H2>
                <t.P>Lorem ipsum</t.P>
                <t.P>Dolor sit amet</t.P>
                <LinkButton primary bold className="link" as="a"
                  target="_blank"
                  href="#">
                  Lorem ipsum
                </LinkButton>
              </DivWrapper>
            </BlockContent>
          </Block>
          <Block>
            <BlockContent>
              <DivWrapper>
                <t.H2 bold>Placeholder website</t.H2>
                <t.P>Lorem ipsum</t.P>
                <t.P>Dolor sit amet</t.P>
                <LinkButton primary bold className="link" as="a"
                  target="_blank"
                  href="#">
                  Lorem ipsum
                </LinkButton>
              </DivWrapper>
              <DivWrapper>
                <ItemImage src={Placeholder} alt="Placeholder title" />
              </DivWrapper>
            </BlockContent>
          </Block>*/
          }
          <WorkWithMe>
            <t.H1 green>Get in touch with me</t.H1>
            <t.LargeP>Fancy working with me? Contact me for more info! </t.LargeP>
            <HireMe onClick={this.openContactPopup} book>
              Contact me
            </HireMe>
            <SocialDiv>
              <div>
                <a
                  href="https://www.github.com/shivamsingha"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#171515' }}
                >
                  <FontAwesomeIcon size="2x" icon={faGithubSquare} title="Shivam's GitHub profile" />
                </a>
              </div>
              <div>
                <a
                  href="https://www.facebook.com/shivamsingha"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#3b5998' }}
                >
                  <FontAwesomeIcon size="2x" icon={faFacebookSquare} title="Shivam's Facebook profile" />
                </a>
              </div>
              <div>
                <a
                  href="https://twitter.com/shivamsingha100"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#1da1f2' }}
                >
                  <FontAwesomeIcon size="2x" icon={faTwitterSquare} title="Shivam's Twitter profile" />
                </a>
              </div>
              <div>
                <a
                  href="https://www.instagram.com/shivamsingha.in"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#c13584' }}
                >
                  <FontAwesomeIcon size="2x" icon={faInstagram} title="Shivam's Instagram profile" />
                </a>
              </div>
            </SocialDiv>
          </WorkWithMe>
        </Layout>
        <HireMePopup open={openHireMePopup} handleClose={this.handleRequestDemoClose} />
      </HomepageWrapper>
    );
  }
}

export default Homepage;

export const pageQuery = graphql`
  query {
    avatarHomepage: file(relativePath: { eq: "avatar.jpg" }) {
      ...fluidImage
    }
  }
`;
